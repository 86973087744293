<template>
<div class="container-fluid">
    <Header />
    <div class="row bg-blue content-padding pdt-70 relative d-flex">
      <div class="col-md-8">
        <h3 class="color-white roboto-bold heading">
          PERSONAL LOAN
        </h3>
        <h5 class="color-white roboto mgb-30 sub-heading">Get a personal loan in few steps</h5>
        <div class="row mgb-30">
            <div class="col-12" style="padding:0px">
          <form>
              <div class="col-8 form-group mgb-30">
                 <label for="work-profile" class="color-white">Work Profile*</label>
                <select id="work-profile"  v-model.trim="$v.page.$model"
                :class="{'is-invalid': validationStatus($v.page)}" v-model="page" name="page" class="form-control" @change="onchangeBL(page)">
                    <option value="">Please Select</option>  
                    <option value="salaried">Salaried</option>
                    <option value="self-employed">Self Employed</option>
                </select>
                  <div v-if="!$v.page.required&&flg" class="error-message color-red sub-heading">Please select your Work Profile.</div>
              </div>
               
              <div class="col-12 form-group padttop10">
                  <!-- <router-link :to="page+'/basic-detail'"> -->
                  <button type="button" @click="redirect()" class="btn form-button button-blue salPLWorkProfileCont d-flex-inline justify-content-center align-items-center color-white bg-blue">Get Started</button>
                  <!-- </router-link> -->
              </div>
             
          </form>
          </div>
          <ApplyReview />
        </div>
         
      </div>
      <div class="col-md-4">
       <ApplyFeature v-bind:list="list" />
      </div>
    </div>
    <OtherPages v-bind:page="loan"/>
    <Footer
     content="For instance, if Megha applies for a Personal loan of INR 3 Lacs and get the loan sanctioned at the rate of 11.99% with a tenure range of 5 years, in such a case her EMI would be INR 6,672 and complete repayment amount till the end of 5 years tenure would be INR 4,00,309 (i.e 6,672 EMI * 60 months), wherein the total interest amount is INR 1,00,309 along with principal repayment of INR 3,00,000. Few additional charges like those of minimal one-time processing fee ranging from 0.5% to 3% of loan amount, pre-payment penalty on an earlier foreclosure of loan ranging from 1% to 5% may also apply. T& C apply."
     />
</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ApplyFeature from '../../sub-components/ApplyFeature';
import ApplyReview from '../../sub-components/ApplyReview';
import Header from '../../sub-components/Header';
import Footer from '../../sub-components/Footer';
import OtherPages from '../../sub-components/OtherPages';
export default {
  
  name: "LandingPage",
  data: function()
  {
      return{
        page:'',
        flg:false,
          list:[
          {
            "title":"Personal Loan Starting at 9.99%",
            "new":true
          },
          {
            "title":"Best Offer to choose from 20+ Banks",
            "new":false
          },
          {
            "title":"No Bank Visits required",
            "new":true
          },
          {
            "title":"Cashback upto Rs 5,000",
            "new":true
          },
          {
            "title":"Instant Paperless Approvals",
            "new":true
          },
          {
            "title":"Free & Quick Service",
            "new":false
          },
          {
            "title":"Servicing Pan India Cities",
            "new":false
          },
          {
            "title":"Dedicated Loan Professional",
            "new":true
          },
          {
            "title":"Money in Account in 24 hrs",
            "new":true
          },
        ]
      }
  },
  mounted: function(){
   
   var make_qry = 'utm_source='+this.$route.query.utm_source+'&utm_medium='+this.$route.query.utm_medium+'&utm_campaign='+this.$route.query.utm_campaign+'&adgroupid='+this.$route.query.adgroupid+'&keyword='+this.$route.query.keyword+'&utm_term='+this.$route.query.utm_term+'&device='+this.$route.query.device+'&network='+this.$route.query.network+'&Adposition='+this.$route.query.adposition
   console.log(make_qry)
   // var make_qry = 'utm_source='+this.$route.query.utm_source+'&utm_medium='+this.$route.query.utm_medium+'&utm_campaign='+this.$route.query.utm_campaign
    localStorage.setItem('source',make_qry)
  },
 
  // created(){
  //   history.pushState(null, document.title, location.href);
  //   history.back();
  //   history.forward();
  //   window.onpopstate = function () {
  //       history.go(1);
  //   };

  // },
  computed:{
    loan(){
           return this.page === 'self-employed' ? 'business-loan' : 'personal-loan'
         }
   },
  validations: {
    page: {required}
},
   methods: {
     
     onchangeBL(val){
       if(val=='self-employed'){
         this.list = []
      this.list.push(
          {
            "title":"Business Loan Starting at 12.99%",
            "new":false
          },
          {
            "title":"Best Offer to choose from 20+ Banks",
            "new":false
          },
          {
            "title":"No Bank Visits required",
            "new":true
          },
          {
            "title":"Cashback upto Rs 5,000",
            "new":true
          },
          {
            "title":"Instant Paperless Approvals",
            "new":true
          },
          {
            "title":"Free & Quick Service",
            "new":false
          },
          {
            "title":"Servicing Pan India Cities",
            "new":false
          },
          {
            "title":"Dedicated Loan Professional",
            "new":true
          },
          {
            "title":"Money in Account in 24 hrs",
            "new":true
          },
        )
       }else{
          this.list = []
      this.list.push(
          {
            "title":"Personal Loan Starting at 9.99%",
            "new":true
          },
          {
            "title":"Best Offer to choose from 20+ Banks",
            "new":false
          },
          {
            "title":"No Bank Visits required",
            "new":true
          },
          {
            "title":"Cashback upto Rs 5,000",
            "new":true
          },
          {
            "title":"Instant Paperless Approvals",
            "new":true
          },
          {
            "title":"Free & Quick Service",
            "new":false
          },
          {
            "title":"Servicing Pan India Cities",
            "new":false
          },
          {
            "title":"Dedicated Loan Professional",
            "new":true
          },
          {
            "title":"Money in Account in 24 hrs",
            "new":true
          },
        )
       }
     },
     validationStatus: function(validation) {
              return typeof validation != "undefined" ? validation.$error : false;
      },
     redirect(){
       

       this.flg = true

       this.$v.$touch();
       if (this.$v.$pendding || this.$v.$error) return;
         this.$router.push(this.page+'/basic-detail');
       
     }
   },
  components: {
    ApplyFeature,
    ApplyReview,
    Header,
    Footer,
    OtherPages,
  },
};
</script>
<style scoped>
h3{
    margin-bottom: 10px;
}
.padttop10{
  margin-top: 25px;
}

</style>
